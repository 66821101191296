import React from 'react'
import {GoLocation} from 'react-icons/go'

import styles from './styles.module.css'

export default function FixAddressManually({onClick}) {
  return (
    <div>
      <div className={styles.button} onClick={onClick}>
        <GoLocation size={15} className={styles.pinIcon} />
        <div className={styles.text}>Ingresa tu dirección manualmente</div>
      </div>
    </div>
  )
}
