import React, {useState} from 'react'
import Place from '@components/fields/Place'
import useShouldRequireCity from '@hooks/useShouldRequireCity'
import useAddressOutOfBounds from '@page-components/Checkout/Checkout/Delivery/Address/AddressOutOfBounds/useAddressOutOfBounds'
import CitySelector, {
  CityOption
} from '@page-components/Checkout/Checkout/Delivery/Address/Update/CitySelector'
import OutOfBoundsAlert from '@page-components/Checkout/Checkout/Delivery/Address/Update/OutOfBoundsAlert'
import SelectedStore from '@page-components/Checkout/Checkout/Delivery/Address/Update/SelectedStore'
import {useTranslation} from 'next-i18next'
import {Field} from 'simple-react-form'

import styles from '@page-components/Checkout/Checkout/Delivery/Address/Update/styles.module.css'

export default function AddressField(props: AddressFieldProps) {
  const {preferences, openCustom, setOpenCustom, showOutOfBoundsMsg, loading} = props
  const shouldRequireCity = useShouldRequireCity()
  const [selectedCity, setSelectedCity] = useState<CityOption>(null)

  return shouldRequireCity && !preferences.address ? (
    <>
      <CitySelector changeSelectedCity={setSelectedCity} />
      {selectedCity && (
        <AddressInputField
          preferences={preferences}
          showOutOfBoundsMsg={showOutOfBoundsMsg}
          openCustom={openCustom}
          setOpenCustom={setOpenCustom}
          selectedCity={selectedCity}
          loading={loading}
        />
      )}
    </>
  ) : (
    <AddressInputField
      preferences={preferences}
      showOutOfBoundsMsg={showOutOfBoundsMsg}
      openCustom={openCustom}
      setOpenCustom={setOpenCustom}
      selectedCity={selectedCity}
      loading={loading}
    />
  )
}

function AddressInputField(props: AddressInputProps) {
  const {preferences, showOutOfBoundsMsg, openCustom, setOpenCustom, selectedCity, loading} = props
  const outOfBoundsMsg = useAddressOutOfBounds(preferences)
  const displayOutOfBoundsMsg = showOutOfBoundsMsg && outOfBoundsMsg && !loading
  const {t} = useTranslation('website', {keyPrefix: 'order.options.updateBasicOptions'})

  return (
    <>
      <Field
        fieldName="placeId"
        passProps={{
          placeholderText: t('address.placeholder'),
          openCustom,
          setOpenCustom,
          selectedCity
        }}
        type={Place}
      />
      {displayOutOfBoundsMsg ? (
        <OutOfBoundsAlert message={outOfBoundsMsg} />
      ) : preferences.placeId ? (
        <SelectedStore preferences={preferences} />
      ) : (
        <div className={styles.example_label}>
          <span>{t('address.example')}</span>
        </div>
      )}
    </>
  )
}

interface AddressFieldProps {
  preferences: any
  openCustom: boolean
  setOpenCustom: (openCustom: boolean) => void
  showOutOfBoundsMsg: boolean
  loading: boolean
}

interface AddressInputProps {
  preferences: any
  showOutOfBoundsMsg: boolean
  openCustom: boolean
  setOpenCustom: (openCustom: boolean) => void
  selectedCity: CityOption
  loading: boolean
}
