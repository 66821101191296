import React from 'react'
import AutoForm from '@components/AutoForm'
import Button from '@components/Button'
import useMessage from '@hooks/useMessage'
import useRef from '@hooks/useRef'
import useState from '@hooks/useState'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import {useTranslation} from 'next-i18next'
import {Field} from 'simple-react-form'

export default function UpdateAddress({placeholderText = '', ...props}) {
  const {t} = useTranslation('website')
  const {address, autoSave} = props
  const showMessage = useMessage()
  const [hasChanges, setHasChanges] = useState(false)
  const button = useRef()
  const form = useRef(null)

  return (
    <AutoForm
      mutation="updateAddress"
      doc={{addressId: address._id, address}}
      buttonRef={button}
      ref={form}
      useFormTag={false}
      onChange={() => setHasChanges(true)}
      onSuccess={address => {
        if (!autoSave) {
          showMessage('Dirección actualizada correctamente')
          props.close()
        }
      }}>
      <Field
        fieldName="address.addressLine2"
        type={Text}
        placeholder={
          placeholderText || t('order.options.updateBasicOptions.addressLine2.placeholder')
        }
        onBlur={() => {
          if (autoSave && hasChanges) {
            form.current.submit()
          }
        }}
      />
      {autoSave ? null : (
        <Button primary ref={button}>
          Confirmar
        </Button>
      )}
    </AutoForm>
  )
}
