import React from 'react'

export default function Map({location, big = false}) {
  const {lat, lng} = location
  const height = big ? '200px' : '150px'

  return (
    <iframe
      width="100%"
      height={height}
      frameBorder="0"
      scrolling="no"
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
      marginHeight="0"
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
      marginWidth="0"
      style={{border: 0, borderRadius: '4px 4px 0px 0px'}}
      loading="lazy"
      src={`https://maps.google.com/maps?width=100%25&height=200&hl=en&q=${lat},${lng}+(location)&t=&z=15&ie=UTF8&iwloc=B&output=embed`}
    />
  )
}
