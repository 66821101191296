import React from 'react'
import get from 'lodash/get'

import FixAddressManually from './FixAddressManually'
import Map from './Map'

import styles from './styles.module.css'

export default function MapPreview({address, onClick, big = false}) {
  if (!address) return null
  const {lat, lng} = get(address, 'location', {})
  if (!lat || !lng) return null

  return (
    <div className={styles.mapContainer}>
      <Map location={{lat, lng}} big={big} />
      <FixAddressManually onClick={onClick} />
    </div>
  )
}
