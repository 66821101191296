import React, {useState} from 'react'
import Loading from '@components/SuspenseLoading/Loading'
import deliveryFragment from '@data/fragments/checkout/deliveryFragment'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import useCheckoutVersion from '@hooks/useCheckoutVersion'
import useMessage from '@hooks/useMessage'
import useWidth from '@hooks/useWidth'
import useAddressOutOfBounds from '@page-components/Checkout/Checkout/Delivery/Address/AddressOutOfBounds/useAddressOutOfBounds'
import useInitialData from '@page-components/Layout/useInitialData'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import get from 'lodash/get'
import {useTranslation} from 'next-i18next'
import {Form} from 'simple-react-form'

import isMapAvailable from './MapPreview/isMapAvailable'
import AddressField from './AddressField'
import MapPreview from './MapPreview'
import UpdateAddress from './UpdateAddress'

import styles from './styles.module.css'

export default function Update({
  time = null,
  showMapRight = false,
  showOutOfBoundsMsg = false,
  loading = false,
  setLoading = (loading: boolean) => {},
  isFromHeader = false,
  ...props
}) {
  const {preferences, onChange} = props
  const {website} = useInitialData()
  const {t} = useTranslation('website', {keyPrefix: 'order.options.updateBasicOptions'})
  const mutate = useMutate()
  const showMessage = useMessage()
  const outOfBoundsMsg = useAddressOutOfBounds(preferences)
  const [openCustom, setOpenCustom] = useState(false)
  const screenWidth = useWidth()
  const mobile = screenWidth <= 768
  const checkoutVersion = useCheckoutVersion()

  const save = async ({placeId}) => {
    setLoading(true)
    try {
      const {setUserPreferences} = await mutate({
        mutation: gql`
          mutation setPlaceIdCheckout($websiteId: ID, $placeId: ID) {
            setPlaceId(websiteId: $websiteId, placeId: $placeId) {
              ...checkoutDeliveryPreferences
            }
          }
          ${deliveryFragment}
        `,
        variables: {websiteId: preferences.websiteId, placeId},
        refetchQueries: ['getCheckout', 'getMyOrderPreferences']
      })

      sendPosthogData(
        setUserPreferences && setUserPreferences.store
          ? EVENTS.address.accept
          : EVENTS.address.failed,
        {
          checkoutVersion,
          ...setUserPreferences
        }
      )

      if (onChange) {
        onChange()
      }

      showMessage('Dirección guardada')
    } catch (error) {
      showMessage(error)
    }

    setLoading(false)
  }

  const displayUpdateAddress = preferences.address && !outOfBoundsMsg && !loading
  const mapAvailable = isMapAvailable(website, get(preferences, 'store.countryCode'))

  if (showMapRight && preferences.address && !mobile && mapAvailable) {
    return (
      <div
        className={`${styles.bigScreenContainer} ${isFromHeader ? styles.headerContainer : null}`}>
        <div className={styles.options}>
          <Form state={preferences} onChange={save} useFormTag={false}>
            <AddressField
              preferences={preferences}
              openCustom={openCustom}
              setOpenCustom={setOpenCustom}
              showOutOfBoundsMsg={showOutOfBoundsMsg}
              loading={loading}
            />
          </Form>
          {loading && <Loading height={150} />}
          {displayUpdateAddress ? (
            <>
              <UpdateAddress
                close={props.close}
                address={preferences.address}
                autoSave={props.autoSave}
              />
              <div className={styles.example_label}>
                <span>{t('addressLine2.example')}</span>
              </div>
            </>
          ) : null}
          {time}
        </div>
        <div className={styles.map}>
          {preferences.address ? (
            <MapPreview
              address={preferences.address}
              onClick={() => setOpenCustom(true)}
              big={true}
            />
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Form state={preferences} onChange={save} useFormTag={false}>
        <AddressField
          preferences={preferences}
          showOutOfBoundsMsg={showOutOfBoundsMsg}
          openCustom={openCustom}
          setOpenCustom={setOpenCustom}
          loading={loading}
        />
      </Form>
      {loading && <Loading height={150} />}
      {displayUpdateAddress ? (
        <>
          <UpdateAddress
            close={props.close}
            address={preferences.address}
            autoSave={props.autoSave}
          />
          <div className={styles.example_label}>
            <span>{t('addressLine2.example')}</span>
          </div>
        </>
      ) : null}
      {preferences.address && mapAvailable ? (
        <MapPreview address={preferences.address} onClick={() => setOpenCustom(true)} />
      ) : null}
      {time}
    </div>
  )
}
